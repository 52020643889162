footer {
  display: flex;
  align-items: center;
  height: 60px;

  span {
    font-size: 22px;
    color: white;

    @media (max-width: 1440px) {
      font-size: 16px;
    }

    @media (max-width: 800px) {
      font-size: 13px;
    }
  }
}