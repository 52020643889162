.main-body {
  background-color: black;
}

.main-content {
  margin-top: $header-height;
  // min-height: calc(99vh - (#{$header-height} + #{$footer-height}));
  max-width: calc(100vw);
}

.main-content>.container {
  scroll-margin-top: $header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 20px;
  // padding-bottom: 20px;
  max-width: 100%;
}

.content-default>.container {
  width: 100%;
  align-items: flex-start;
  display: block;
}

.not-show {
  display: none;
}