//layout
$header-height: 106px;
$header-height-md: 66px;
$header-height-sm: 56px;
$logo-height: 77px;
$logo-height-md: 47px;
$logo-height-sm: 37px;

//views
$primary-yellow: #FFBB00;
$primary-green: #00AA81;
$primary-gray: #3A3A3A;
$primary-dark-gray: #1C1C1C;
$border-gray: #858585;
$bg-gray: #4B4B4B;
$h3-font-size: 64px;
$h3-font-size-md: 56px;
$h3-font-size-sm: 32px;
$h4-font-size: 38px;
$h4-font-size-md: 30px;
$h4-font-size-sm: 22px;
$content-font-size: 36px;
$content-font-size-md: 28px;
$content-font-size-sm: 20px;
$small-title-font-size: 28px;
$small-title-font-size-md: 20px;
$small-title-font-size-sm: 15px;