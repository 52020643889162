h3 {
  color: $primary-yellow;
  font-size: $h3-font-size;
  font-weight: 400;
  margin: 40px 0;

  @media (max-width: 1440px) {
    font-size: $h3-font-size-md;
  }

  @media (max-width: 800px) {
    font-size: $h3-font-size-sm;
  }
}

.bg-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: black;

  img {
    width: 100%;
    height: auto;
  }

  h2 {
    position: absolute;
    left: 6%;
    color: white;
    font-weight: 400;
    font-size: 78px;
    line-height: 172.4px;
    white-space: break-spaces;

    @media (max-width: 1440px) {
      font-size: 70px;
    }

    @media (max-width: 800px) {
      font-size: 46px;
      line-height: 120px;
    }
  }
}

.space {
  background-color: black;
}

.partner-container {
  background-color: white;
  padding-left: 19%;
  padding-right: 19%;

  .partner-imgs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    padding: 20px 15px;

    .partner-img-container {
      // margin-left: 75px;
      // margin-right: 75px;
      align-self: center;
      justify-self: center;

      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;

        @media (max-width: 1440px) {
          max-width: 80%;
        }
      }
    }
  }
}

.choose-container {
  position: relative;
  display: flex;
  margin-top: 40px;

  img {
    object-fit: cover;
  }

  h3 {
    position: absolute;
    font-size: $h3-font-size;
    left: 7%;
    top: 130px;

    @media (max-width: 1440px) {
      font-size: $h3-font-size-md;
    }

    @media (max-width: 800px) {
      font-size: $h3-font-size-sm;
      top: 80px;
    }
  }

  h4 {
    position: absolute;
    color: white;
    font-size: $h4-font-size;
    font-weight: 700;
    left: 7%;
    top: 240px;

    @media (max-width: 1440px) {
      font-size: $h4-font-size-md;
    }

    @media (max-width: 800px) {
      font-size: $h4-font-size-sm;
      top: 170px;
    }
  }

  .choose-list {
    position: absolute;
    color: white;
    font-size: 32px;
    font-weight: 700;
    left: 7%;
    top: 390px;

    @media (max-width: 1440px) {
      font-size: 24px;
    }

    @media (max-width: 800px) {
      font-size: 16px;
      top: 270px;
    }

    .choose-item {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      span {
        margin-left: 28px;
      }
    }
  }
}

.info-container {
  display: flex;
  padding: 50px 10%;
  margin-top: 119px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;

  .info-address {
    flex: 1;

    .info-name {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 20px;
    }

    span:first-child {
      font-size: 46px;
      color: $primary-yellow;
      margin-bottom: 0;

      @media (max-width: 1440px) {
        font-size: 38px;
      }

      @media (max-width: 800px) {
        font-size: 22px;
      }
    }

    span {
      display: block;
      font-size: $small-title-font-size;
      color: white;
      margin-bottom: 20px;

      @media (max-width: 1440px) {
        font-size: $small-title-font-size-md;
      }

      @media (max-width: 800px) {
        font-size: $small-title-font-size-sm;
      }
    }

    img {
      @media (max-width: 1440px) {
        width: 128px;
      }

      @media (max-width: 800px) {
        width: 88px;
      }
    }
  }

  .info-link {
    flex: 1;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 65px;
    margin-left: 20px;

    @media (max-width: 1440px) {
      margin-top: 50px;
    }

    @media (max-width: 800px) {
      margin-top: 40px;
    }

    .info-useful {
      span:first-child {
        font-size: $content-font-size;
        color: $primary-green;

        @media (max-width: 1440px) {
          font-size: $content-font-size-md;
        }

        @media (max-width: 800px) {
          font-size: $content-font-size-sm;
        }
      }
    }

    .info-social {
      span:first-child {
        font-size: $content-font-size;
        color: $primary-green;

        @media (max-width: 1440px) {
          font-size: $content-font-size-md;
        }

        @media (max-width: 800px) {
          font-size: $content-font-size-sm;
        }
      }

      img {
        @media (max-width: 1440px) {
          width: 28px;
        }

        @media (max-width: 800px) {
          width: 20px;
        }
      }
    }

    span {
      display: block;
      cursor: pointer;
      font-size: $small-title-font-size;
      color: white;
      margin-bottom: 30px;

      @media (max-width: 1440px) {
        font-size: $small-title-font-size-md;
      }

      @media (max-width: 800px) {
        font-size: $small-title-font-size-sm;
        margin-bottom: 22px;
      }
    }
  }
}