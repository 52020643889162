.contact-container {
  display: flex;
  scroll-margin-top: $header-height;
  margin-top: 100px;
  height: 844px;

  @media (max-width: 800px) {
    height: 612px;
  }

  .contact-container-bg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .contact-content {
    display: flex;
    position: absolute;
    left: 10%;
    right: 10%;

    .contact-info {
      flex: 3;
      background-color: black;
      padding: 35px;
      margin-right: 30px;
      border: 5px solid white;
      border-radius: 5px;

      .contact-info-item {
        display: flex;
        color: white;
        margin-bottom: 30px;

        .contact-info-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          min-width: 40px;
          margin-right: 35px;

          @media (max-width: 800px) {
            width: 20px;
            min-width: 20px;
            margin-right: 20px;

            img {
              height: 40%;
            }
          }
        }

        .contact-info-title {
          display: grid;
          font-size: 24px;
          margin-bottom: 10px;

          @media (max-width: 1440px) {
            font-size: 19px;
          }

          @media (max-width: 800px) {
            font-size: 16px;
          }
        }

        .contact-info-content {
          font-size: 20px;

          @media (max-width: 1440px) {
            font-size: 15px;
          }

          @media (max-width: 800px) {
            font-size: 13px;
          }
        }
      }

      .contact-map {
        width: 100%;
        height: 500px;
        // position: 'relative'

        @media (max-width: 1440px) {
          height: 350px;
        }

        @media (max-width: 800px) {
          height: 250px;
        }
      }
    }

    .contact-form {
      flex: 5;
      background-color: black;
      border: 5px solid white;
      border-radius: 5px;
      padding: 30px 30px 40px 30px;

      .contact-form-info {
        display: flex;
      }

      .contact-textfield {
        flex: 1;

        span {
          display: grid;
          margin-bottom: 15px;
          color: white;
          font-size: 24px;

          @media (max-width: 1440px) {
            font-size: 19px;
          }

          @media (max-width: 800px) {
            font-size: 16px;
          }
        }
      }

      .send-btn {
        margin-top: 40px;
        display: grid;
        cursor: pointer;
        color: $primary-yellow;
        font-size: 28px;
        font-weight: 700;

        @media (max-width: 1440px) {
          font-size: 20px;
        }

        @media (max-width: 800px) {
          font-size: 17px;
        }
      }
    }
  }
}