.content-container {
  background-color: black;
  padding: 32px 8%;

  .about-us-container {
    scroll-margin-top: $header-height;

    span {
      color: white;
      font-size: $content-font-size;

      @media (max-width: 1440px) {
        font-size: $content-font-size-md;
      }

      @media (max-width: 800px) {
        font-size: $content-font-size-sm;
      }
    }

    .about-us-content {
      display: flex;
      align-items: center;
    }

    .about-us-label {
      flex: 1;
      text-align: justify;

      p {
        display: inline;
      }
    }

    .about-us-logo {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      img {
        width: calc(30vw);
      }
    }
  }

  .service-container {
    scroll-margin-top: $header-height;

    h3 {
      margin-top: 129px;
      margin-bottom: 66px;

      @media (max-width: 800px) {
        margin-top: 89px;
        margin-bottom: 46px;
      }
    }

    .service-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: dense;
      padding: 15px;

      .service-item-container {
        width: calc((100vw - 550px) / 3);
        background-color: $bg-gray;
        border-radius: 15px;

        @media (max-width: 1440px) {
          width: calc((100vw - 400px) / 3);
        }

        @media (max-width: 800px) {
          width: calc((100vw - 250px) / 3);
        }

        img {
          width: calc(100% - 70px);
          height: auto;
          margin: 35px 35px 10px 35px;

          @media (max-width: 800px) {
            width: calc(100% - 40px);
            margin: 20px 20px 10px 20px;
          }
        }

        span {
          color: white;
          font-size: $small-title-font-size;

          @media (max-width: 1440px) {
            font-size: $small-title-font-size-md;
          }

          @media (max-width: 800px) {
            font-size: $small-title-font-size-sm;
          }
        }

        .service-item-title {
          margin-left: 41px;
          margin-bottom: 68px;

          @media (max-width: 800px) {
            margin-left: 21px;
            margin-bottom: 28px;
          }
        }

        .service-item-more {
          margin-left: 41px;
          margin-bottom: 12px;
          cursor: pointer;

          @media (max-width: 800px) {
            margin-left: 21px;
          }
        }
      }
    }
  }

  .succesful-case-container {
    h3 {
      margin-top: 170px;
      margin-bottom: 118px;

      @media (max-width: 800px) {
        margin-top: 90px;
        margin-bottom: 68px;
      }
    }

    .succesful-case-content {
      display: flex;

      .succesful-case-left {
        width: 36vw;
        margin-right: 118px;

        @media (max-width: 800px) {
          margin-right: 78px;
        }

        img {
          width: 100%;
        }
      }

      .succesful-case-right {
        flex: 1;
        background-color: black;
      }
    }
  }
}
