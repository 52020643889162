.content-header {
  height: $header-height;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: black;

  @media (max-width: 1440px) {
    height: $header-height-md;
  }

  @media (max-width: 800px) {
    height: $header-height-sm;
  }

  .header-left {
    display: flex;
    align-items: center;

    a {
      display: flex;
    }

    h3 {
      font-size: 36px;
      font-weight: 400;
      color: white;
      align-self: center;
      pointer-events: none;

      @media (max-width: 1440px) {
        font-size: 28px;
      }

      @media (max-width: 800px) {
        font-size: 20px;
      }
    }

    img {
      height: $logo-height;
      cursor: pointer;
      margin-left: 23px;

      @media (max-width: 1440px) {
        height: $logo-height-md;
      }

      @media (max-width: 800px) {
        height: $logo-height-sm;
      }
    }
  }

  .header-right {
    display: flex;

    .header-item {
      display: flex;
      align-items: center;
      margin-right: 36px;
      font-size: 28px;
      color: white;
      cursor: pointer;

      @media (max-width: 1440px) {
        font-size: 20px;
      }

      @media (max-width: 800px) {
        font-size: 14px;
        margin-right: 16px;
      }

      .header-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        min-width: 28px;
        margin-right: 7px;

        @media (max-width: 1440px) {
          width: 24px;
          min-width: 24px;
        }

        @media (max-width: 800px) {
          width: 14px;
          min-width: 14px;
        }
      }
    }
  }
}