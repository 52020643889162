.custom-textfield {
  width: 100%;
  background-color: $primary-gray;
  border: 1px solid $border-gray !important;
  border-radius: 10px;

  @media (max-width: 1440px) {
    height: 50px;
  }

  input {
    padding: 14px;
    color: white;
    font-size: 24px;

    @media (max-width: 1440px) {
      font-size: 19px;
    }
  }
}

textarea {
  width: calc(100% - 28px) !important;
  background-color: $primary-dark-gray !important;
  padding: 14px;
  color: white;
  font-size: 24px;
}